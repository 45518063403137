<template>
  <br /><br />
  <div class="d-flex justify-content-center align-items-center custom-font">
    <div class="container">
      <div class="container-fluid " style="background-color:white; padding: 20px; margin-top: 1%; margin-bottom: 1%;">

        <h2 lass="py-3">Eine besondere Weinverkostungsreihe</h2>
        <br />
        <div class="row">
          <div class="col d-flex align-items-start col-md-6 mx-auto">
            <p class="myPara" style="font-size:20px">
              <strong>Erlebt über den Sommer mit "Wein am Fluss", einer einzigartigen Weintastingreihe in Zusammenarbeit von Jannis Böttger und dem Ahoi!.</strong>
              <br/>
              <br/>
              Taucht mit Jannis Böttger in die Welt der nachhaltigen Weine und Naturweine ein. Die Tastings finden im idyllischen Ambiente des Ahoi! statt – naturverbunden und direkt an der Fulda im Herzen von Kassel
            </p><br/>
          </div>
          <br/><br/>
            <h3 style="margin-top:30px">Reservierung und Verfügbarkeit: 
              <a href="https://www.eventbrite.de/e/wein-am-fluss-tickets-941791512767" target="_blank">Eventbrite</a>
            </h3>

        </div>
        <br/><br/>
        <div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="pics/1.png" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/2.png" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/3.png" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/4.png" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/5.JPG" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/6.JPG" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/7.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/8.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/9.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/10.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/11.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/12.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/13.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/14.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/15.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/16.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>


  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="rectangle">
              <h3>Freut euch auf</h3><br />
              <p style="text-align:left">
              <ul>
                <li><strong> Einführung in nachhaltige Weine:</strong> Lernt die faszinierenden Hintergründe und Besonderheiten nachhaltiger Weine kennen.</li>
                <li><strong> Wöchentlich wechselnde Weine:</strong> Jede Woche erwartet Euch eine neue Auswahl spannender Weine zum Probieren. </li>
                <li><strong> Leckere Snacks:</strong> Zu den Weinen gibt es vom Team des frieda gebackene Foccacia und Oliven von OEL Berlin. </li>

              </ul>
              </p><br/>
              <p style="font-size: 13px;">Die Snacks enthalten Allergene. Da der Wein im Vordergrund stehen soll, können wir leider nicht auf Allergien und Unverträglickeiten eingehen. Falls ihr euch unsicher seid bezüglich eurer Allergien oder Unverträglichkeiten schreibt mir gerne an hallo@weinamfluss.de</p>
              <br/><br/><br/>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="rectangle">
              <h3>Veranstaltungsdetails</h3><br />
              <p style="text-align:left">
              <ul>
                <li><strong>Angebot:</strong> Es gibt 4-5 unterschiedliche Weine - Wasser und Snacks sind inklusive.</li>
                <li><strong>Dauer:</strong> Die Tastings dauern in etwa 2 Stunden.</li>
                <li><strong>Wann:</strong> Mehrmals die Woche bis Ende Sommer. Es wird danach noch weitere Termine geben – Infos folgen.</li>
                <li><strong>Extraevents:</strong> Für Infos folgt mir auf <a href="https://www.instagram.com/jannisboettger" target="_blank">Instagram</a></li>
                <li><strong>Preis:</strong> Ein Ticket kostet 49 € pro Person</li>
                <li><strong>Regen:</strong> Bei Regen findet das Tasting überdacht statt. Der Ort bleibt der gleiche.</li>
                <li><strong>Gruppe:</strong> Um allen Weinliebhaber*innen die gleiche Chance zu geben an unseren Tastings teilzunehmen, limitieren wir die maximalen Buchungen auf 4 Tickets pro Reservierung. Infos zu Gruppenbuchungen findet ihr auf der Website. </li>
              </ul>
              </p>
              <br /><br />
              <strong>
              Reservierung und Verfügbarkeit: <a href="https://www.eventbrite.de/e/wein-am-fluss-tickets-941791512767" target="_blank">Eventbrite</a>
              </strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="rectangle">
              <h3>Gruppenbuchungen</h3><br />
              <p style="text-align:left">
              <ul>
                <li><strong>Details:</strong> Ihr wollt als Gruppe kommen? Wir bieten Euch die Möglichkeit, als Gruppe auch zu anderen Tagen oder Uhrzeiten zu reservieren.</li>
                <li><strong>Kosten:</strong> Mindestpreis 500€ für bis zu 10 Personen + 50€ für jede weitere Person bis max. 20 Personen.</li>
                <li><strong>Extra/Upgrade:</strong> Wollt ihr mehr oder andere Weine? Dies ist mit einer Preisanpassung möglich</li>
                <li><strong>Anfrage:</strong> Schreibt mir gerne an <a href="mailto:hallo@weinamfluss.de">hallo@weinamfluss.de</a></li>
              </ul>
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="rectangle">
              <h3>Private Veranstaltung</h3><br />
              <p style="text-align: justify;">
              <ul>
                <li><strong>Details:</strong> Ihr könnt die Tastings auch für Zuhause oder in der Firma buchen.</li>
                <li><strong>Kontakt:</strong> Schreibt mir gerne an <a href="mailto:hallo@weinamfluss.de">hallo@weinamfluss.de</a></li>
              </ul>
              </p>
              <br/><br/><br/><br/><br/>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 4%;">
          <h2 lass="py-3" style="color: rgb(139, 0, 0);"><i class="fa-solid fa-heart" aria-hidden="true"></i></h2>
          <div class="col d-flex align-items-start col-md-8 mx-auto"><br>
            <p class="myPara"> Wir freuen uns darauf, Euch bei unseren Tastings begrüßen zu dürfen! </p>
          </div>
        </div>
      </div>

      <!--
      <br/>
      <div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="pics/1.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/2.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/3.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/4.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
    <div class="carousel-item">
      <img src="pics/5.jpeg" class="d-block w-100 img-fluid" alt="...">
    </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
-->
      <!--
      <div class="container-fluid border border-dark" style="background-color:white; padding: 20px; margin-bottom: 1%;">
      <h3 class="text-center">Event Registrations</h3>
      </div>
      <div class="container-fluid border border-dark" style="background-color:white; padding: 50px;">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
  <thead>
    <tr>
      <th scope="col">Daten</th>
      <th scope="col">Zeit</th>
      <th scope="col">Slot</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="reservation in reservations" :key="reservation.date">
        <td>{{ reservation.date }}</td> 
        <td>{{ reservation.time }}</td>
        <td>
                <span
                  class="badge rounded-pill"
                  :class="{
                    'text-bg-success': reservation.slots < 7,
                    'text-bg-warning': reservation.slots >= 7 && reservation.slots < 10,
                    'text-bg-danger': reservation.slots == 10
                  }"
                >
                  {{ getSlotStatus(reservation.slots) }}
                </span>
          </td>
      </tr>
  </tbody>
</table>
</div>

      </div>
    -->
      <!-- Footer 7 - Bootstrap Brain Component -->
      <footer class="footer" style="margin-top: 1%;">

        <!-- Colophon - Bootstrap Brain Component -->
        <div class="pb-2 pb-md-5">
          <div class="container overflow-hidden">
            <div class="row">
              <div class="col">
                <div class="container-fluid border border-dark" style="background-color:white">
                  <div class="row gy-4 gy-lg-0 p-3 p-md-4 p-xxl-5 align-items-md-center">
                    <div class="col-xs-12 col-sm-6 col-lg-4 order-0 order-lg-0">
                      <div class="footer-logo-wrapper text-center text-sm-start">
                        <a href="#!">
                          <img src="../assets/logo-min-300.png" alt="Logo" width="100" class="img-fluid">
                        </a>
                      </div>
                    </div>

                    <div class="col-xs-12 col-lg-4 order-2 order-lg-1">
                      <div class="colophon-wrapper">
                        <div class="footer-copyright-wrapper text-center">
                          &copy; 2024. All Rights Reserved.
                        </div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-lg-4 order-1 order-lg-2">
                      <div class="social-media-wrapper">
                        <ul class="list-unstyled m-0 p-0 d-flex justify-content-center justify-content-sm-end">
                          <li class="">
                            <a href="https://www.instagram.com/jannisboettger" class="link-dark link-opacity-75-hover">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                class="bi bi-instagram" viewBox="0 0 16 16">
                                <path
                                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    </div>

  </div>









</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      reservations: [],
    };
  },
  mounted() {
    this.fetchReservation();
  },
  methods: {
    fetchReservation() {
      const sheetId = '1wkgvAqn8d7YK8JEQxsqDTqVFsOn3jw6lqgzaNMH_xIo';
      const apiKey = 'AIzaSyBJS1xadauuC_VenakHelmZrDKc3adBklY';
      const range = 'reservation!A2:C23'; // Adjust range as needed

      axios
        .get(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`)
        .then(response => {
          const rows = response.data.values;

          this.reservations = rows.slice(1).map(row => ({
            date: row[0],
            time: row[1],
            slots: row[2]
          }));
        })
        .catch(error => {
          console.error('Error fetching data from Google Sheets:', error);
        });

      console.log(this.reservations)
    },
    getSlotStatus(slot) {

      if (slot === 10) {
        return 'Booked';
      }

      return slot + ' / 10'
    },
  },
};
</script>
